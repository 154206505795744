.phone-input-container .react-phone-number-input__input {
  border: none !important;
  /* You can also add additional styling here if needed */
}

a {
  text-decoration: none;
}

.icon-box {
  width: 70px;
  margin: auto;
  height: 70px;
  border-radius: 50%;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 11;
  background-color: #fff;
}

.icon-box div:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-list .col-2 {
  position: relative;
}

.progress-list .col-2:after {
  content: '';
  width: 70%;
  height: 2px;
  background-color: #ccc;
  position: absolute;
  left: 105px;
  top: 32px;
}

.progress-list .col-2:last-child:after {
  display: none;
}

/* .my-focus .form-control:focus {
  color: "none" !important
} */