.myImges { margin-top: 15px;    }
.myImges figure { position: relative; height: 150px;overflow: hidden; border-radius: 10px; }
.myImges  figure img { width: 100%;height: 100%;object-fit: cover;object-position: center; }
.myImges  figcaption { display: flex; position: absolute;right: 0;top:0;padding: 10px; }
.myImges  figcaption button { border: none; width: 35px;height: 35px;background-color: rgb(255, 255, 255,0.2);border-radius: 50%;margin-left: 5px;display: flex;align-items: center;justify-content: center; }
.ReactCrop { width: 100%; }
.ReactCrop__image { width: 100%; }

.sidebar-nav .nav-link {white-space: normal;margin-left:-10px; }
.sidebar-nav .nav-group-items {font-size: 14px;}
.sidebar-nav .nav-link.active {background: rgb(255 255 255 / 13%);margin-left: 5px;}




/* .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    height: 33px !important;
    width: 595px !important
} */

.MuiFormControl-root{width: 100%;}
.MuiFormControl-root input.MuiInputBase-input{
    padding: 2px 0px 7px 15px !important
}
input#email {
    height: 38px;
}
.alert-danger {
    border-color:#ff0000 !important ;
    background-color: #fff !important;
}


.order_scanned td{
    background-color: rgb(171, 220, 171);
}

.warning-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
    gap: 10px;
}

.warning-content img{
    height: 80px;
    width: 80px;
   
}

.warning-content h1{
    font-weight: normal;
    font-size: xx-large;
}

.warning-content p{
    font-size: 18px;
}

.warning-modal .modal-footer{
    border-top: 0px !important;
  
    padding: 0px !important;
}

.warning-modal .modal-footer button{
    display: block;
    width: 100%;
    padding: 12px !important;
    margin: 0 !important;
    background-color: red;
    border:none !important;
    border-radius: 0px !important;
}


.warning-content .img-con{
    padding: 18px;
    border: 1px solid gray;
    border-radius: 50%;
}

.warning-content .img-con img{
    height: 50px;
    width: 50px;
   
}
.all-order-scan-modal{
    margin: 35px 15px;
}
.all-order-scan-modal .order-success-txt{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}

.all-order-scan-modal .order-success-txt h5{ 
    color :red;
    margin-bottom: 0;
}
.all-order-scan-modal .order-success-txt p{
    color : gray;
 }
 

 .all-order-scan-modal .warning-content{
    margin: 15px 10px;
 }
 .all-order-scan-modal button{
    padding: 10px 20px;
    background-color: #2fe72f;
    border: none !important;
    color: red;
    font-size: 20px;

 }


.all-order-scan-modal button:hover{
    background-color: #2fe72f;    
    color: red;
}